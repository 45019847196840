/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { signInWithGoogle } from "../../firebase/FirebaseUtils";


import "../../styles/loginForm.css";
import "../../styles/verifyEmail.css";

import { userLogin } from "../../redux/loginSlice";
import { useDispatch } from "react-redux";
import { auth } from "../../firebase/FirebaseUtils";
import { useForm } from "react-hook-form";
import {
  DOMAIN,
  ROUTER_HOME,
  ROUTER_LANDING_PAGE,
  ROUTER_RESET_PASSWORD_FORM,
  ROUTER_VERIFY_EMAIL,
} from "../../config/Constant";

import soyYoLogoNegro from "../../../src/assets/images/soy-yo-logo-negro.png";
import soyYoNameNegro from "../../../src/assets/images/soy-yo-nombre-negro.png";
import LoadingSpinnerV2 from "../../components/LoadingSpinnerV2";

import imageLogin from "../../../src/assets/images/background/image-login.png";
import imageReset from "../../../src/assets/images/background/image-reset.png";

import spark from "../../../src/assets/images/background/spark.png";

import cardSVG from "../../../src/assets/svg/card.svg";
import commentSVG from "../../../src/assets/svg/comment.svg";
import signalSVG from "../../../src/assets/svg/signal.svg";
import { objectToUserRole } from "../../utils/objectToUserRole";
import { RegisterFormV2 } from "./RegisterFormV2";

import "../landing_page/Components/LandingPageStyles.css";
import { sendVerificationEmail } from "../admin/Api/UserServices";
import { toast } from "react-toastify";

const LoginForm = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { register, handleSubmit } = useForm();

  const [errorLoginMessage, setErrorLogin] = useState("");
  const [loading, setLoading] = useState(false);

  const [registerForm, setRegisterForm] = useState(false);

  let showSendVerificationEmail = false;
  if (
    errorLoginMessage ===
    "Tu cuenta no ha sido verificada, revisa tu correo electrónico."
  ) {
    showSendVerificationEmail = true;
  }

  function showRegisterForm() {
    setRegisterForm(true);
  }

  function hideRegisterForm() {
    setRegisterForm(false);
  }

  const handleLogin = async (data) => {
    setErrorLogin("");
    setLoading(true);
    await signInUser(data);
    setLoading(false);
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    setErrorLogin("");
  
    try {
      const result = await signInWithGoogle();
      const user = result.user;
      let idToken = await user.getIdToken(true);
  
      const response = await fetch(DOMAIN + "auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${idToken}`,
        },
      });
  
      await response.json().then((data) => {
        if (data.detail === "Email not Verified") {
          return history(ROUTER_VERIFY_EMAIL);
        }
  
        let userRole = objectToUserRole(data.data);
        dispatch(userLogin({ token: idToken, userRole }));
        history(ROUTER_HOME);
      });
    } catch (error) {
      console.error("Error en autenticación con Google:", error);
      setErrorLogin("No se pudo iniciar sesión con Google.");
    } finally {
      setLoading(false);
    }
  };

  
  const signInUser = async (data) => {
    const { email, password } = data;
    try {
      const res = await signInWithEmailAndPassword(
        auth,
        email.trim(),
        password
      );
      const user = res.user;
      let idToken = await user.getIdToken(true);
      const response = await fetch(DOMAIN + "auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${idToken}`,
        },
      });
      await response.json().then((data) => {
        const token = idToken;
        if (data.detail === "Email not Verified") {
          if (email.endsWith("@soyyo.digital")) {
            return history(ROUTER_VERIFY_EMAIL);
          }
          setErrorLogin(
            "Tu cuenta no ha sido verificada, revisa tu correo electrónico."
          );
          return;
        }
        let userRole = objectToUserRole(data.data);
        dispatch(userLogin({ token, userRole }));
        return history(ROUTER_HOME);
      });
    } catch (e) {
      showLoginErrorMessage(e); // Error de inicio de sesión
    }
  };
  
  function handleSendEmailAgain() {
    sendVerificationEmail(auth.currentUser);
    toast.success("Enlace reenvíado exitosamente", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  const showLoginErrorMessage = (error) => {
    const errorMessages = {
      "auth/wrong-password": "La contraseña ingresada es incorrecta",
      "auth/user-not-found":
        "No existe ninguna cuenta con ese correo electrónico",
      "auth/invalid-email": "Ingrese un correo válido",
    };

    let errorMessage =
      errorMessages[error.code] ?? "Ocurrio un error inesperado";

    setErrorLogin(errorMessage);
  };

  return (
    <div className="font-montserrat flex w-full h-full bg-stone-950 radial-background">
      <div className="md:w-[45%] sm:min-w-[22rem] md:max-w-[30rem] rounded-none md:rounded-r-[3rem] w-full h-full flex flex-col items-center bg-stone-50">
        <Link
          to={ROUTER_LANDING_PAGE}
          className="p-4 mt-8 opacity-80 rounded-3xl flex flex-row"
        >
          <img className="h-9" src={soyYoLogoNegro} alt="SoyYo logo"></img>
          <img className="h-9" src={soyYoNameNegro} alt="SoyYo marca"></img>
        </Link>

        {registerForm ? (
          <>
            <RegisterFormV2 hideRegisterForm={hideRegisterForm} />
          </>
        ) : (
          <>
            <h1 className="mt-[8vh] mb-14 font-manrope text-2xl transition-font-size font-medium text-stone-700">
              Inicia a SoyYo
            </h1>

            <div className="max-w-[26rem] flex flex-col w-full">
              <form
                onSubmit={handleSubmit(handleLogin)}
                className="px-6 w-full flex flex-col items-center"
              >
                <div className="w-full text-input-group relative">
                  <input
                    id="email"
                    className="login-input px-4 py-3 text-sm font-medium transition-font-size w-full rounded-lg text-input-login force-label-up"
                    type="text"
                    {...register("email")}
                    required
                  />
                  <label
                    htmlFor="email"
                    className="pointer-events-none transition-all"
                  >
                    Correo electrónico
                  </label>
                </div>
                <div className="mt-8 w-full text-input-group relative">
                  <input
                    id="password"
                    className="login-input px-4 py-3 text-sm font-medium transition-font-size w-full rounded-lg text-input-login force-label-up"
                    type="password"
                    {...register("password")}
                    required
                  />
                  <label
                    htmlFor="password"
                    className="pointer-events-none transition-all"
                  >
                    Contraseña
                  </label>
                </div>
                {errorLoginMessage ? (
                  <div className="px-2 py-1 mt-2 border-2 text-[13px] w-fit bg-red-200 text-red-700 border-red-300">
                    {errorLoginMessage}
                    {showSendVerificationEmail ? (
                      <button
                        onClick={handleSendEmailAgain}
                        type="button"
                        className="font-medium hover:underline"
                      >
                        Reenvíar enlace de verificación
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <Link
                  className="mt-4 text-sm mr-auto font-medium text-stone-600 hover:text-stone-800"
                  to={ROUTER_RESET_PASSWORD_FORM}
                >
                  ¿Olvidaste tu contraseña?
                </Link>
                <button
                  type="submit"
                  className="px-4 py-3 w-full mt-12 relative flex transition-font-size rounded-xl font-semibold text-stone-100 bg-stone-950"
                >
                  <div className="mx-auto">
                    <LoadingSpinnerV2
                      className={`${
                        !loading ? "invisible" : ""
                      } absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
                      color="dark:text-stone-600"
                      fill="fill-stone-200"
                      size={24}
                    />
                    <div className={loading ? "invisible" : ""}>
                      INICIAR SESIÓN
                    </div>
                  </div>
                </button>

               {/*  <button
  type="button"
  onClick={handleGoogleLogin}
  className="mt-4 px-4 py-3 w-full flex items-center justify-center transition-font-size rounded-xl font-semibold text-stone-100 bg-blue-500 hover:bg-blue-600"
>
  <img
    src="https://upload.wikimedia.org/wikipedia/commons/0/09/IOS_Google_icon.png"
    alt="Google Logo"
    className="w-5 h-5 mr-2"
  />
  Iniciar sesión con Google
</button> */}

                {/* <button
                  onClick={showRegisterForm}
                  className="px-4 py-3 mt-2 w-full text-center font-semibold hover:underline"
                >
                  CREAR CUENTA
                </button> */}
              </form>
            </div>
          </>
        )}
      </div>

      <div className="h-full hidden relative md:flex w-full px-2 justify-center items-center">
        <img
          className="absolute wiggle-class-animation neon-light-animation left-0 lg:left-[5%] transition-[left] duration-500 top-24 w-64"
          src={commentSVG}
          style={{ animationDelay: "200ms" }}
        />
        <img
          className="absolute wiggle-class-animation neon-light-animation right-0 lg:right-[15%] transition-[right] duration-500 top-16 w-64"
          src={signalSVG}
          style={{ animationDelay: "500ms" }}
        />
        <img
          className="absolute wiggle-class-animation neon-light-animation right-4 lg:right-[20%] transition-[right] duration-500 bottom-24 w-80"
          src={cardSVG}
          style={{ animationDelay: "0ms" }}
        />
        <img className="pulse-star absolute left-3/4 mb-8 w-8" src={spark} />
        <img
          className="pulse-star absolute left-1/4 top-3/4 mb-8 w-8"
          src={spark}
        />
        <img
          className="pulse-star absolute left-1/2 top-1/4 mb-8 w-8"
          src={spark}
        />
        <div className="skew-24 absolute border-2 border-white max-h-[40rem] h-full aspect-[3/8] rounded-xl"></div>

        <img
          className="absolute mb-8 max-w-[25vw] max-h-[40rem]"
          src={registerForm ? imageReset : imageLogin}
        />
      </div>
    </div>
  );
};

export default LoginForm;
