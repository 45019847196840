import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import MockImage from "../../assets/landing-page/mockups/phone.png";
import { Navbar } from "./Components/Navbar";

import "./Components/LandingPageStyles.css";
import { BenefitsCard } from "./Components/BenefitsCard";

import {
  FaShareAlt,
  FaCreditCard,
  FaQrcode,
  FaChartLine,
  FaFeatherAlt,
  FaQuoteLeft,
  FaWhatsapp,
} from "react-icons/fa";

import cardSVG from "../../assets/landing-page/decoration/card.svg";
import commentSVG from "../../assets/landing-page/decoration/comment.svg";
import signalSVG from "../../assets/landing-page/decoration/signal.svg";

import virtualCard from "../../assets/landing-page/decoration/plan-virtual2.png";
import basicCard from "../../assets/landing-page/decoration/plan-simple-modelo.png";
import customCard from "../../assets/landing-page/decoration/plan-medio-modelo.png";
import customCardExample from "../../assets/landing-page/decoration/plan-medio-capacitar.png";
import fullDesignCard from "../../assets/landing-page/decoration/plan-avanzado-modelo.png";
import fullDesignCardExample from "../../assets/landing-page/decoration/plan-avanzado-gamefic.png";
import nfctag from "../../assets/landing-page/decoration/3dtag.png";
import credentials from "../../assets/landing-page/decoration/credentials.png";
import booking from "../../assets/landing-page/decoration/booking.png";
import bookingExpample from "../../assets/landing-page/decoration/bookingExample.png";

import nfc from '../../assets/landing-page/decoration/nfc.png';

import firstStep from "../../assets/landing-page/steps/first_step.jpeg";
import payStep from "../../assets/landing-page/steps/pay_step.jpeg";
import customizeStep from "../../assets/landing-page/steps/customize_step.jpeg";
import receiveStep from "../../assets/landing-page/steps/receive_step.jpeg";
import { FaCalendarAlt } from "react-icons/fa";

import { GiEarthAmerica } from "react-icons/gi";
import { ProductsCard } from "./Components/ProductsCard";
import { StepsCard } from "./Components/StepsCard";
import { ReviewCard } from "./Components/ReviewCard";
import { ReviewsList } from "./Components/ReviewsList";
import { Footer } from "./Components/Footer";
import { FrequentlyAsked } from "./Components/FrequentlyAsked";
import { FrequentQuestion } from "./Components/FrequentQuestion";
import { ROUTER_PAYMENT, ROUTER_PROMOTION } from "../../config/Constant";
import { useVerifyToken } from "../payment_page/Hooks/useVerifyToken";

import TokenRefresher from "../../components/TokenRefresher";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { scrollToById } from "./Services/Utils/scrollToById";
import { ClientsCard } from './Components/ClientsCard';

import { getProducts } from '../admin_super/Api/products';

export function LandingPage(props) {

  const token = useSelector((state) => state.login.token);
  const { isVerified, loading, user } = useVerifyToken(token);
  const [products, setProducts] = useState([]);

  const handleButtonClick = () => {
    // Open a new tab with the desired URL when the button is clicked
    window.open("https://api.whatsapp.com/send/?phone=59169108791&text=Hola,+me+gustaría+saber+más+sobre+Soy+Yo+y+sus+tarjetas+digitales/", "_blank"/* , rel="noreferrer" */);
  };

  const location = useLocation();
  const navigate = useNavigate();

  function navigateToProductById(id) {
    return () => {
      navigate(`${ROUTER_PAYMENT}?id=${id}`);
    };
  }

  useEffect(() => {
    getProducts()
      .then((response) => {
        const responseData = response.data;
        if (Array.isArray(responseData)) {
          setProducts(responseData);
        } else {
          console.error("Expected an array but got:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });

    const state = location.state;
    if (state?.to) {
      scrollToById(state.to)();
    }
  }, [location]);

  return (
    <>

      <TokenRefresher />
      <div id="start"
        className="radial-background relative flex flex-col justify-between w-full"
      >
        <div className="absolute h-full w-full pointer-events-none">
          <img
            className="card-animation neon-light-animation h-48 absolute"
            src={cardSVG}
            alt=""
          />
          <img
            className="comment-animation neon-light-animation h-48 absolute"
            src={commentSVG}
            alt=""
          />
          <img
            className="signal-animation neon-light-animation h-48 absolute"
            src={signalSVG}
            alt=""
          />
        </div>

        <Navbar user={user} />
        <div className="flex flex-col lg:flex-row">
          <button
            className="fixed bottom-4 right-4 p-2 bg-green-500 text-white rounded-full z-50"
            onClick={handleButtonClick}
          >
            <FaWhatsapp className="text-4xl" />
          </button>


          <div className="px-12 2xl:pb-24 self-center mx-auto max-w-2xl 2xl:max-w-4xl text-center lg:text-left text-stone-50">
            <h1 className="mt-4 lg:mt-0 text-3xl lg:text-[40px] 2xl:text-5xl xl:leading-tight">
              Consigue tu tarjeta <strong> digital </strong> al
              <strong> instante </strong>
            </h1>
            <p className="mt-6 leading-loose text-xl 2xl:text-2xl 2xl:leading-normal">
              <strong> SoyYo Digital </strong> es una tarjeta de presentación
              digital que te permite compartir <strong> quién eres</strong>, con
              cualquier persona <strong> al instante. </strong>
            </p>


            <div className="mt-12 flex flex-col gap-6 md:flex-row md:justify-center">
              <Link
                to={ROUTER_PAYMENT}
                className="px-16 md:px-24 py-3 text-center text-base rounded-2xl border border-transparent text-black font-semibold bg-white transition-colors hover:border-stone-500 hover:bg-stone-900 hover:text-stone-50 w-full md:w-auto"
              >
                CREA TU TARJETA
              </Link>

              <Link
                to={`${ROUTER_PROMOTION}?id=4`}
                className="px-16 md:px-24 py-3 text-center text-base rounded-2xl border border-transparent text-black font-semibold bg-yellow-400 transition-colors hover:border-stone-500 hover:bg-yellow-500 hover:text-stone-900 w-full md:w-auto"
              >
                VER PROMOCIONES
              </Link>
            </div>
          </div>

          <div className="mt-16 lg:mt-4 lg:mr-36 p-12 max-w-[24rem]  h-fit relative mx-auto rounded-t-[3rem] bg-white">
            <div className="left">
              <div></div>
            </div>
            <div className="right">
              <div></div>
            </div>
            <img src={MockImage} alt="SoyYo app" className="mx-auto" />
          </div>
        </div>
      </div>

      <div id="benefits" className="pb-32 pt-20 font-montserrat text-stone-90">
        <h2 className="text-2xl lg:text-3xl text-center">
          Beneficios de SoyYo Digital
        </h2>

        <div className="mt-12 px-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-center justify-items-center h-fit gap-y-16 sm:gap-y-24 gap-x-12">
          <BenefitsCard
            title="Comparte"
            description="Comparte tu tarjeta personalizada a potenciales clientes y contactos"
            icon={<FaShareAlt />}
          />
          <BenefitsCard
            title="Tarjeta"
            description="Utiliza una tarjeta inteligente con tecnología NFC"
            icon={<FaCreditCard />}
          />
          <BenefitsCard
            title="QR"
            description="Muestra tu información de contacto en todas partes con un QR"
            icon={<FaQrcode />}
          />
          <BenefitsCard
            title="Mejorando"
            description="Mejorando constantemente para aumentar la experiencia del usuario"
            icon={<FaChartLine />}
          />
          <BenefitsCard
            title="Sencillo"
            description="Personaliza tu tarjeta fácilmente con nuestra interfaz amigable"
            icon={<FaFeatherAlt />}
          />
          <BenefitsCard
            title="Ecológico"
            description="Reduce el uso de papel de tarjetas de presentación físicas"
            icon={<GiEarthAmerica />}
          />
        </div>
      </div>

      <div id="products"
        className="pb-8 sm:pb-32 pt-24 flex flex-col font-montserrat bg-stone-100"
      >
        <h2 className="px-4 font-bold text-2xl lg:text-3xl text-center">
          Nuestros productos
        </h2>

        <h2 className="mt-6 px-4 text-2xl lg:text-2xl text-center font-medium ">Tarjetas y Perfiles</h2>

        <div className="mt-12 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 items-center justify-items-center h-fit gap-y-9 p-3 gap-x-1">
          {products
            .filter(
              product => product.status === 1 && product.type === "tarjeta" /* && product.id !== 3 */
            ) // Filtra los productos con status=1, type="tarjeta" y excluye id=3
            .map((product) => (
              <ProductsCard
                key={product.id} // Asegúrate de usar un identificador único aquí
                image={product.image}
                hoverText={
                  <>
                    ¡Pedir una!
                    <br />
                  </>
                }
                title={product.title}
                description={product.description}
                buttonText={
                  <div className="flex ">
                    <p className="font-normal">Pide una&nbsp;|&nbsp;</p>
                    <p className="font-bold text-base">  {product.price}</p>
                  </div>
                }
                /* link={`${ROUTER_PAYMENT}?id=${product.id}`} */
                link={`${ROUTER_PROMOTION}?id=${product.id}`}
                onClickImageOrButton={navigateToProductById(product.id)}
              />
            ))}
        </div>


        {/*  <h2 className="mt-6 px-4 text-2xl lg:text-2xl text-center font-medium ">Acessorios Complementarios</h2>


        <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-center justify-items-center h-fit gap-y-9 p-3 gap-x-1">
          {products
            .filter(product => product.status === 1 && product.type === "accesorio") // Filtra los productos con status=1
            .map((product) => (
              <ProductsCard
                key={product.id} // Asegúrate de usar un identificador único aquí
                image={product.image}
                hoverText={
                  <>
                    ¡Pedir una!
                    <br />
                  </>
                }
                title={product.title}
                description={product.description}
                buttonText={
                  <div className="flex ">
                    <p className="font-normal">Pide una&nbsp;|&nbsp;</p>
                    <p className="font-bold text-base">  {product.price}</p>
                  </div>
                }
                link={`${ROUTER_PAYMENT}?${product.id}`}
                onClickImageOrButton={navigateToProductById(product.id)}
              />
            ))}
        </div> */}

      </div>

      <div id="steps"
        className="pb-32 pt-20 flex flex-col font-montserrat bg-stone-100"
      >
        <h2 className="px-4 text-2xl lg:text-3xl font-semibold text-center">
          Pasos para conseguir tu tarjeta
        </h2>

        <div className="mt-6 sm:mt-12 px-8 grid grid-cols-1 sm:grid-cols-2 items-center justify-items-center h-fit gap-y-16 sm:gap-y-20 gap-x-12">
          <StepsCard
            image={firstStep}
            index="1"
            description="Elige el plan SoyYo que mejor se acomode a tus necesidades"
          />
          <StepsCard
            image={payStep}
            index="2"
            description="Registrate y realiza el pago del servicio en nuestro sitio web"
          />
          <StepsCard
            image={customizeStep}
            index="3"
            description="Personaliza tu perfil en tu cuenta de SoyYo"
          />
          <StepsCard
            image={receiveStep}
            index="4"
            description="¡Recibe tu tarjeta y compartela con todos! (no olvides plantar las semillas)"
          />
        </div>
      </div>

      {/* <div id="clients" className="pb-32 pt-20 flex flex-col font-montserrat bg-stone-100">
        <h2 className="px-4 text-2xl lg:text-3xl text-center">Nuestros Clientes</h2>
        <p className="text-center text-gray-600 mb-12">Conoce a nuestros clientes</p>
        <div className="text-center">
          <ClientsCard />
        </div>
      </div> */}

      <div id="comments" className="pb-16 lg:pb-32 relative flex flex-col font-montserrat">
        <div className="absolute left-10 top-1/3">
          <FaQuoteLeft className="text-8xl opacity-10" />
        </div>
        <h2 className="mt-12 lg:mt-24 px-4 text-2xl md:text-3xl lg:text-4xl font-medium text-center">
          Que dicen las personas de nosotros
        </h2>
        <div className="mt-16 lg:mt-32">
          <ReviewsList
            reviews={[
              <ReviewCard
                nStars={4}
                starColor="rgb(250,10,0)"
                review='"SoyYo es simplemente increíble. Me encanta la forma en que puedo compartir mi información de contacto con un solo toque."'
                author="David Lopez"
              />,
              <ReviewCard
                nStars={5}
                starColor="rgb(250,220,50)"
                review=' "La calidad de las tarjetas de presentación digitales con NFC de SoyYo son excepcionales. El diseño es elegante y moderno".'
                author="Mario"
              />,
              <ReviewCard
                nStars={5}
                starColor="rgb(250,50,200)"
                review='"Definitivamente recomendaría SoyYo a cualquier que busque una forma innovadora de presentarse".'
                author="Valentina"
              />,
            ]}
          />
        </div>
      </div>

      <div id="questions" className="pb-32 pt-12 lg:pt-24 flex justify-center text-stone-900 bg-stone-100">
        <FrequentlyAsked
          categories={[
            {
              title: "Tarjetas de contacto NFC",
              element: (
                <>
                  <FrequentQuestion
                    question="¿Qué es una tarjeta inteligente NFC?"
                    answer="Es una tarjeta con un chip NFC que permite transferir tu información de contacto a dispositivos compatibles."
                  />
                  <FrequentQuestion
                    question="¿Qué información se puede compartir con mi tarjeta?"
                    answer="De todo! Puedes compartir fácilmente tu número de teléfono, nombre, imágenes, sitio web de tu empresa y editarlos en cualquier momento desde tu perfil. "
                  />
                  <FrequentQuestion
                    question="¿La otra persona necesita una aplicación para recibir mi información?"
                    answer="Nop! Tu perfil se abre en un navegador (Chrome, Firefox, Opera, etc.), el cuál cualquier smartphone tiene."
                  />
                </>
              ),
            },
            {
              title: "Diseño de tarjeta",
              element: (
                <>
                  <FrequentQuestion
                    question="¿Puedo tener una tarjeta SoyYo con mi logo?"
                    answer="Claro! El plan 'Full Design' es perfecto para tí, podrás personalizar el diseño de tu tarjeta al máximo con ayuda de nuestros diseñadores y el diseño final será aprobado por tí."
                  />
                  <FrequentQuestion
                    question="¿Qué diferencia hay entre el plan 'Basic' y 'Full Design'?"
                    answer="La diferencia más importante esta en el diseño de la tarjeta, con el plan 'Full Design' puedes tener cualquier color o logotipo. Mientras que con el plan 'Basic' el logo de SoyYo estará impreso en la tarjeta."
                  />
                </>
              ),
            },
            {
              title: "Para empresas",
              element: (
                <>
                  <FrequentQuestion
                    question="¿Puedo comprar varias tarjetas SoyYo?"
                    answer="Si. Puedes pedir tantas como desees. Incluso puedes administrar muchas tarjetas desde una interfaz administrativa."
                  />
                  <FrequentQuestion
                    question="¿Qué ventajas tiene la interfaz administrativa?"
                    answer="Tendrás acceso al perfil de todos tus colaboradores, donde podrás editarlos, cambiar configuración de la cuenta, analizar estadísticas y mucho más."
                  />
                </>
              ),
            },
          ]}
        />
      </div>


      <Footer />
    </>
  );
}
