import axios from 'axios';
import { DOMAIN, LOCALHOST_DOMAIN } from "../../../config/Constant";
import { useState, useEffect } from 'react';

// Función para obtener datos del usuario
export const useUser = (token) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(DOMAIN + "auth/user", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        });

        if (!response.ok) {
          console.error(`Error fetching user data: ${response.status}`);
          return;
        }

        const userData = await response.json();
        setUser(userData);

      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [token]);

  return user;
};

// Función para crear una nueva reserva
export const createBooking = async (data, token) => {
  try {
    const response = await axios.post(`${DOMAIN}booking/`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Función para obtener todas las reservas
export const getAllBookings = async (userId, token) => {
  try {
    const response = await axios.get(`${DOMAIN}booking/get/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const countPendingBookings = (bookings) => {
  try {
    if (Array.isArray(bookings)) {
      const pendingBookings = bookings.filter((booking) => booking.data.status_booking === 0);
      
      
      return pendingBookings.length;
    } else {
      console.error("bookings no es un array");
      return 0;
    }
  } catch (error) {
    console.error('Error en countPendingBookings:', error);
    return 0;
  }
};

export const getAllBookingsWithCount = async (userId, token) => {
  try {
    const response = await axios.get(`${DOMAIN}booking/get/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    const allBookings = response.data;

    // Verifica si allBookings es un array
    if (Array.isArray(allBookings)) {
      const pendingBookingsCount = countPendingBookings(allBookings);
      return { allBookings, pendingBookingsCount };
    } else if (typeof allBookings === 'object' && allBookings !== null) {
      // Si response.data es un objeto (pero no nulo), consideramos que es un solo elemento y lo convertimos en un array
      const pendingBookingsCount = countPendingBookings([allBookings]);
      return { allBookings: [allBookings], pendingBookingsCount };
    } else {
      // Manejo de error o devolución de un valor predeterminado
      console.error("allBookings no es un array");
      return { allBookings: [], pendingBookingsCount: 0 }; // O cualquier otro valor predeterminado
    }
  } catch (error) {
    throw error;
  }
};


export const updateBooking = async (bookingId, updatedData, token) => {
  try {
    const response = await axios.put(`${DOMAIN}booking/get/${bookingId}`, updatedData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Función para obtener la configuración de reserva
export const getBookingSettings = async (userId) => {
  try {
    const response = await axios.get(`${DOMAIN}booking/setting/${userId}`, {
      
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Función para actualizar la configuración de reserva
export const updateBookingSettings = async (updatedSettings, token) => {
  try {
    const response = await axios.post(`${DOMAIN}booking/setting`, updatedSettings, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};









//Para multiples configuration bookings

export const ConfBookingListView = async (userId, token) => {
  try {
    const response = await axios.get(`${DOMAIN}booking/conf/${userId}/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ConfBookingCreateView = async (data, userId, token) => {
  try {
    const response = await axios.post(`${DOMAIN}booking/conf/${userId}/create`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ConfBookingUpdateView = async (bookingId, updatedData, token) => {

  try {
    const response = await axios.put(
      `${DOMAIN}booking/conf/${bookingId}/update/`,
      updatedData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ConfBookingDeleteView = async (bookingId, token) => {
  try {
    const response = await axios.delete(`${DOMAIN}booking/conf/${bookingId}/delete/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getConfBookingDetails = async (bookingId, token) => {
  try {
    const response = await axios.get(`${DOMAIN}booking/conf/get/${bookingId}`, {
      
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};



